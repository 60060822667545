<template>
    <nav-bar :show="ticketStore.showNavbar"></nav-bar>
    <router-view v-slot="{ Component }">
      <transition name="route" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

    <!--dynamically loading in dialogs-->
    <ModalFrame :modalID="manageStore.currentDialog.id" :modalTitle="manageStore.currentDialog.title" :modalSize="manageStore.currentDialog.size">
        <component
        :is="manageStore.currentDialog.component"
        :componentParameter="manageStore.currentDialog.componentParameter"/>
    </ModalFrame>

    <p class="copyright fst-italic">© Software developed in the WM-AUT Department</p>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import ModalFrame from './dialogs/ModalFrame.vue';
import { useTicketsStore } from "@/stores/tickets";
import { useManageStore } from "@/stores/manage";
import '@/components/ThreeScene/3dloader';
// import * as bootstrap from 'bootstrap';
// window.Modal = bootstrap.Modal;

export default {
  name: 'MainView',
  components: {
    NavBar,
    ModalFrame,
  },
  setup(){

    const ticketStore = useTicketsStore();
    const manageStore = useManageStore();

    //fetchLoop
    setInterval(()=>{
      manageStore.serverfetchRoutine();
    }, 6000)

    return{
      manageStore,
      ticketStore
    }
  }
}

</script>

<style scoped>

#threeviewport{
  position: fixed;
  z-index: 10000;
  top: 0px;
  left: 50%;
  width: 260px;/* only initial! */
  height: 140px;
}

.copyright{
  position: fixed;
  bottom: -5px;
  right: 10px;
}

/* route transition */

.route-enter-from{
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active{
  transition: all 0.3s ease-out;
}
.route-leave-to{
  opacity: 0;
  transform: translateX(-100px);
}
.route-leave-active{
  transition: all 0.3s ease-in;
}
</style>