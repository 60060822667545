<template>
    <div class="viewOffset">
        <p class="fs-4">{{ manageStore.lang("StatsView.color_usage.title") }}</p>
        <div id="plotMaterials"></div>
        <p class="fs-4">{{ manageStore.lang("StatsView.ticket_history.title") }}</p>
        <div id="plotMaterialHistory"></div>
    </div>
</template>

<script>
import { useManageStore } from '@/stores/manage';
import { useStatsStore } from '@/stores/stats';
import { useTicketsStore } from '@/stores/tickets';
import Plotly from 'plotly.js/dist/plotly'
import { onMounted } from 'vue';

export default {
 name: 'StatsView',
 setup(){
    const ticketStore = useTicketsStore();
    const statsStore = useStatsStore()
    const manageStore = useManageStore();
    
    onMounted(()=> {
        initializeChartData();
    });


     async function initializeChartData() {
        const distributionColors = await statsStore.getTicketColors();

        var xValue = ticketStore.printingMaterials;
        var yValue = distributionColors;

        var traceMats = {
            x: xValue,
            y: yValue,
            type: 'bar',
            textposition: 'auto',
            hoverinfo: 'auto',
            marker: {
                color: ticketStore.printingMaterials,
                opacity: 0.6,
                line: {
                    color: 'rgb(8,48,107)',
                    width: 1.5
                }
            }
        };
        var dataMats = [traceMats];

        var layoutMats = {
            barmode: 'stack',
            yaxis: {
                tickmode: 'linear', // Ensures linear spacing
                tick0: 0, // Starting tick at 0
                dtick: 1, // Set tick intervals to 1 to display integers only
            }
        };
        Plotly.newPlot('plotMaterials', dataMats, layoutMats);


        const dataHistory = [];

        const colorsUsage = [
            {
                x: [1, 2, 3, 4],
                y: [10, 15, 13, 17],
            },
            {
                x: [1, 2, 3, 4],
                y: [10, 15, 13, 17],
            },
            {
                x: [1, 2, 3, 4],
                y: [10, 15, 54, 65],
            },
            {
                x: [1, 2, 3, 4],
                y: [20, 15, 43, 23],
            },
            {
                x: [1, 2, 3, 4],
                y: [10, 32, 54, 23],
            },
            {
                x: [1, 2, 3, 4],
                y: [43, 12, 54, 32],
            },
        ];

        for (let i = 0; i < ticketStore.printingMaterials.length; i++) {
            const mat = ticketStore.printingMaterials[i];
            var trace = {
                x: colorsUsage[i].x,
                y: colorsUsage[i].y,
                type: 'scatter',
                name: mat
            };
            dataHistory.push(trace);
        }

        const layoutHistory = {
            barmode: 'stack',
            xaxis: {
                title: 'Months of the Year',
                tickvals: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], // X values as integers
                ticktext: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'], // Map months to integers
            },
            yaxis: {
                title: 'amount of tickets'
            }
        };

        Plotly.newPlot('plotMaterialHistory', dataHistory, layoutHistory);
     }
     return {
        manageStore
     }
 },
}
</script>

<style scoped>

.viewOffset{
    margin-top: 130px;
}

</style>